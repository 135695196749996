import { Error, Input } from '@hse24/shared-components';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { BaseSyntheticEvent, useEffect } from 'react';
import styles from './TextField.module.scss';

export interface TextFieldProps {
  fieldName: string;
  label: string;
  value: string | undefined;
  onChange?: (value?: string) => void;
  className?: string;
  disabled?: boolean;
  maxCharacters?: number;
}

const TextField = ({ fieldName, label, value, disabled, onChange, className }: TextFieldProps) => {
  const { values, setFieldValue, errors, handleChange, handleBlur, touched } =
    // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
    // eslint-disable-next-line
    useFormikContext<any>();

  useEffect(() => {
    setFieldValue(fieldName, value?.trim(), false);
  }, [value]);

  const onChangeEvent = (event: BaseSyntheticEvent) => {
    handleChange(event);
    onChange && onChange(event.currentTarget.value);
  };

  return (
    <div className={cx(className, styles.text_field)}>
      <Input
        className={styles.input}
        name={fieldName}
        type="text"
        label={label}
        invalid={touched[fieldName] && Boolean(errors[fieldName])}
        value={values[fieldName] ?? ''}
        disabled={disabled}
        onChange={onChangeEvent}
        onBlur={(e: BaseSyntheticEvent) => {
          setFieldValue(fieldName, e.currentTarget.value.trim());
          if (handleBlur) {
            handleBlur(e);
          }
        }}
      />
      {touched[fieldName] && errors[fieldName] && (
        <Error error={errors[fieldName] as string} className={styles.error_message} />
      )}
    </div>
  );
};

export default TextField;
