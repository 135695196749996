const t = {
  auth: {
    'shop app hint': 'Sie suchen unsere Shopping App?',
  },
  agencies: {
    'Agency commission': 'Servicepartnerprovision',
    'Create agency': 'Servicepartner anlegen',
    'The creation of the Agency was initiated.': 'Die Anlage des Servicepartners wurde initiiert.',
    Agencies: 'Servicepartner',
    Agency: 'Servicepartner',
    commissionTables: {
      'Only for selected streamers. Please contact your service partner for more information.':
        'Nur für ausgewählte Streamer. Bitte kontaktiere deinen Servicepartner für weitere Informationen.',
    },
  },
  common: {
    Revenue: 'Umsatz',
    Total: 'Gesamt',
    Save: 'Speichern',
    Yes: 'Ja',
    No: 'Nein',
    'Privacy Policy': 'Datenschutzhinweise',
    All: 'Alle',
    Selected: 'Ausgewählt',
    'Products found': 'Produkte gefunden',
    'Products selection': 'Produktsuche',
    'Your latest orders': 'Deine letzten Bestellungen',
    Done: 'Fertig',
    'View all': 'Alle anzeigen',
    'Recording link': 'Aufzeichnung Link',
    Audience: 'Audience',
    More: 'Mehr',
    Okay: 'Okay',
    'Sold out': 'Ausverkauft',
    'Hse Stream': 'HSE Stream',
    'Creator Stream': 'Creator Stream',
    Cancel: 'Abbrechen',
    welcomeBack: 'Willkommen zurück',
    startStream: 'Stream starten',
    editStream: 'Stream bearbeiten',
    soldOut: 'Ausverkauft',
    Voucher: 'Gutschein',
    logOut: 'Abmelden',
    'edit profil': 'Profil bearbeiten',
    'Privacy Settings': 'Datenschutzeinstellungen',
    'add item': 'Artikel hinzufügen',
    'Add selected products': 'Gewählte Produkte hinzufügen',
    'Confirm deletion': 'Löschen bestätigen',
    'Permanently delete': 'Unwiderruflich löschen',
    'Entries per page': 'Einträge pro Seite',
    'Loading...': 'Lädt...',
    Sort: 'Sortieren',
  },
  creators: {
    commissionTables: {
      contentHours: {
        'Expense flat rate in your first three months':
          'Aufwandspauschale in deinen ersten drei Monaten',
        'Flat rate of expenses': 'Aufwandspauschale',
        'less than 300': 'weniger als 300',
        'between 300 and 600': 'zwischen 300 und 600',
        'from 600': 'ab 600',
        'If the sales commission exceeds the administration fee, the administration fee is forfeited. Only the higher amount of the two values will be credited.':
          'Falls die Umsatzprovision die Aufwandspauschale übersteigt, verfällt die Aufwandspauschale.\n' +
          'Es wird immer nur der höhere Betrag der beiden Werte gutgeschrieben.',
      },
      contentMonths: {
        'Credit for show products': 'Gutschrift für Showprodukte',
        'Up to 50€ for show products': 'Bis zu 50€ für Showprodukte',
        'Months 2 and 3 show product credit will only be given if you streamed at least 600 minutes in the previous month.':
          'Du erhältst die Gutschrift für Showprodukte im zweiten und dritten Monat nur, wenn du im Vormonat mindestens 600 Minuten gestreamt hast.',
      },
      'Only the key figure listed here in HELLO for Streamers is decisive for the turnover.':
        'Für den Umsatz ist ausschließlich die hier in HELLO for Streamers aufgeführte Kennzahl ausschlaggebend.',
    },
    Month: 'Monat',
    Credit: 'Gutschrift',
    Live: 'Liveminuten',
    Bonus: 'Prämie',
    Views: 'Aufrufe',
    Likes: 'Gefällt mir',
    Comments: 'Kommentare',
    Orders: 'Bestellungen',
    openInvoices: 'Retouren',
    allProducts: 'Alle Produkte',
    basket: 'Warenkorb',
    Interactions: 'Interaktionen',
    Revenue: 'Einnahmen',
    'Streams Revenue': 'Streamumsatz',
    Commission: 'Provision',
    'in total': 'insgesamt',
    'Commission table': 'Provisionstabelle',
    'Management fee': 'Managementgebühr',
    'Sales commission': 'Umsatzprovision',
    'Net sales': 'Nettoumsatz',
    'Stream period': 'Streamzeit',
    'Remuneration overview': 'Vergütungsübersicht',
    'Total net stream revenue in the selected period':
      'Gesamtstreamnettoumsatz im ausgewählten Zeitraum',
    'Total streaming time in the selected period': 'Gesamtstreamingzeit im ausgewählten Zeitraum',
    'in the selected period': 'im ausgewählten Zeitraum',
    Period: 'Zeitraum',
    'Number of streamers': 'Anzahl Streamer',
    'Search for streamers': 'Nach Streamern suchen',
    'Revenue per streamer': 'Umsatz pro Streamer',
    'Streaming time': 'Streamingzeit',
    'Approved at': 'Freigabedatum',
    'Commission streamer': 'Provision Streamer',
    "You can't start streams at the moment. Please contact your agency.":
      'Du kannst momentan keine Streams starten. Bitte wende dich an deinen Servicepartner.',
    "You can't create shows at the moment. Please contact your agency.":
      'Du kannst momentan keine Shows erstellen. Bitte wende dich an deinen Servicepartner.',
    'Welcome 👋': 'Willkommen 👋',
    'we have sent you a password reset mail':
      'Wir haben dir eine Nachricht gesendet. Jetzt musst du nur noch auf den Link in der E-Mail klicken, um ein neues Passwort zu erstellen.',
    'Hint: check spam folder':
      'Tipp: Wenn du keine E-Mail erhalten hast, überprüfe deinen Spam-Ordner und stelle sicher, dass du auch die korrekte E-Mailadresse angegeben hast.',
    'We need your help!💪': 'Wir brauchen deine Hilfe!💪',
    Hello: 'Hallo',
    'Next level': 'Nächstes Level',
    'Streamer Academy': 'Streamer Academy',
    'Learn the best streamer techniques and tricks.':
      'Lerne die besten Techniken und Tricks für Live',
    'Shopping Streams 🚀': 'Shopping Streams 🚀',
    'Become a pro!': 'Werde zum Profi!',
    ama: {
      'Ask me Anything': 'Ask me Anything',
      'is here! 🎉': 'ist da! 🎉',
      'Learn more': 'Mehr erfahren',
      'Engage more with your audience.': 'Engage more with your audience.',
      'Write here...': 'Write here...',
    },
    'Watch now': 'Jetzt anschauen',
    'You currently have no streams scheduled': 'Du hast derzeit keine Streams geplant',
    'Do you want to prepare more streams': 'Möchtest du weitere Streams vorbereiten?',
    'Schedule a stream': 'Stream planen',
    'Your products': 'Deine Produkte',
    'Find products': 'Produkte finden',
    post: {
      'Posts overall performance': 'Performance deiner Posts',
      'Gained followers': 'Generierte Follower',
      followers: 'Follower',
      'Overall Revenue': 'Gesamtumsatz',
      'All likes': 'Likes',
      'All Comments': 'Kommentare',
      'Upload image': 'Bild hochladen',
      'Select an image to create your post.*':
        'Wählen Sie ein Bild aus, um Ihren Beitrag zu erstellen.*',
      'Add products': 'Produkte hinzufügen',
      'Add up to 50 products.*': 'Füge bis zu 50 Produkte hinzu (erforderlich).',
      Details: 'Bildunterschrift',
      Publish: 'Veröffentlichen',
      'Posts Revenue': 'Postumsatz',
      Edit: 'Bearbeiten',
      ScheduleAt: 'Veröffentlichung',
      DateSelectorTitle: 'Datum & Uhrzeit festlegen',
      instructionsPopUp: {
        title: 'Details zum Datei-Upload',
        instructions: [
          {
            iconTitle: 'Dateigröße & Länge',
            instructionDescription: 'Maximale Dateigröße: 2 GB \n Dauer: bis zu 5 min',
          },
          {
            iconTitle: 'Dateiformat',
            instructionDescription: 'MP4 oder MOV für Videos, JPG oder \n PNG für Bilder',
          },
          {
            iconTitle: 'Video-Auflösung',
            instructionDescription: '720p & 1.080p empfohlen, 4k wird \n derzeit ',
            importantNote: 'nicht unterstützt',
          },
          {
            iconTitle: 'Foto-Auflösung',
            instructionDescription: 'Verwende 1.000px oder \n mehr für die längste Seite',
          },
          {
            iconTitle: 'Seitenverhältnis',
            instructionDescription:
              '9:16 Format für Videos,\n Bilder als Portrait hochladen (4:5 bspw.)',
          },
        ],
        close: 'Hinweis schließen',
      },
    },
    notifications: {
      'We have deleted your missed stream after 48 hours:':
        'Wir haben deinen verpassten Stream nach 48 Stunden gelöscht:',
    },
    voucher: {
      free_shipping: 'Portofrei',
      '%-voucher': '%-Gutschein',
      updated: 'AKTUALISIERT',
      'tooltip text':
        'Wenn du den Gutschein aktivierst, wird dein persönlicher Gutscheincode deinen Zuschauern automatisch nach einer bestimmten Zeit im Livestream angezeigt und automatisch angewendet, wenn sie diesen aktivieren.',
      'Voucher for your viewers': 'Gutschein für deine Zuschauer',
    },
    show: {
      'Hide stream': 'Stream ausblenden',
      'Do you really want to hide the stream?':
        'Möchtest du den Stream wirklich ausblenden? Damit wird die Vorschau in der App und der Webseite nicht mehr angezeigt.',
      'Publish stream': 'Stream veröffentlichen',
      'Expired stream': 'Verpasster Stream',
      'missed planned stream': 'Du hast deinen Stream nicht gestartet:',
      'missed planned stream description':
        'Du kannst deinen Stream bearbeiten und die Startzeit ändern, um beim nächsten Mal damit live zu gehen. Verpasste Streams werden am Ende deiner geplanten Streams angezeigt.',
      'delete countdown': 'Falls du nicht reagierst, löschen wir deinen Stream in',
      Delete: 'Löschen',
      Edit: 'Bearbeiten',
      'delete stream confirmation': 'Bist du sicher, dass du den Stream löschen möchtest?',
      'Successfully deleted': 'Erfolgreich gelöscht.',
      'Stream successfully deleted': 'Stream erfolgreich gelöscht',
      'delete expired streams notification':
        'Dein verpasster Stream wird in 48 Stunden gelöscht. Änderungen sind bis dahin möglich, um dies zu verhindern. Verpasste Streams werden am Ende deiner geplanten Streams angezeigt.',
      Hours: 'Stunden',
      invalidTime:
        'Diese Startzeit ist nicht verfügbar. Bitte ändere deine Eingabe oder gehe zurück, um alle verfügbaren Zeitslots zu sehen.',
      confirmStartTime: 'Startzeit bestätigen',
      timeZone: 'Zeitzone: Berlin',
      CropHint: 'Dunkle Bereiche signalisieren, welche Teile des Bildes verdeckt werden können',
      startShow: 'Streamplanung',
      selectDate: 'Datum und Uhrzeit auswählen',
      imageFormat: 'Format 4:5 bzw. 1080x1350 px',
      exitShowCreation: 'Zurück zur Übersicht',
      showNotStarted: 'Nicht gestartet',
      liveNow: 'Jetzt live',
      startStreamNow: 'Stream jetzt starten',
      openChat: 'Ext. Chat starten',
      selectCreator: 'Creator wählen (erforderlich)',
      DateSelectorTitle: 'Streamzeit auswählen',
      hideEarlyShopping: 'Früh-Shoppen ausblenden',
      streamedByHSEOnly: 'Nur HSE-Streams',
      'Delete Stream': 'Stream löschen',
      'Moderator view': 'Moderatoransicht',
      Products: 'Produkte*',
      'Stream title': 'Stream-Titel*',
      'Scheduled stream start': 'Geplanter Stream-Start*',
    },
  },
  validation: {
    'Enter at least 3 characters!': 'Gib mindestens 3 Zeichen an!',
    'Please enter a valid email address!': 'Bitte gib eine valide E-Mail-Adresse ein!',
    'The maximum is 200 characters': 'Das Maximum beträgt 200 Zeichen',
    'Oh no, we have never seen this email address before. Please check your input again.':
      'Oh nein, diese E-Mail-Adresse haben wir noch nie gesehen. Bitte überprüfe deine Eingabe nochmal.',
    'Easy, just enter your login data': 'Gib einfach deine Login-Daten ein.',
    "Oops, that doesn't look like an email address. Please enter a valid address, such as name@beispiel.com.":
      'Ups, das sieht nicht wie eine E-Mail-Adresse aus. Bitte gib eine gültige Adresse ein, z.B. name@beispiel.com.',
    'Please insert a preview image': 'Bitte lade ein Vorschaubild hoch',
    'Please set a time': 'Bitte lege eine Uhrzeit fest',
    'Please enter a title': 'Bitte gib einen Titel ein',
    'Please include one or more products': 'Bitte plane ein oder mehrere Produkte ein',
    'Please select a creator': 'Bitte wähle einen Creator aus',
  },
  admins: {
    survey: {
      'Only display text feedback': 'Nur Textfeedback anzeigen',
      Streamer: 'Streamer',
      Stream: 'Stream',
      Score: 'Bewertung',
      Type: 'Typ',
      Recording: 'Aufzeichnung',
      Livestream: 'Livestream',
      Post: 'Post',
      IOS: 'iOS',
      Android: 'Android',
      Tablet: 'Tablet',
      Phone: 'Phone',
    },
    Vouchers: {
      Voucher: 'Gutschein',
      Value: 'Value',
      'on all': 'auf alles',
      on: 'auf:',
      'on everything except': 'auf alles außer auf:',
      'except on': 'Außer auf:',
      'Enter voucher code': 'Gutscheincode eingeben',
      'Validity period': 'Gültigkeitszeitraum',
      Discount: 'Rabatt',
      Brand: 'Marke',
      Product: 'Produkt',
      Category: 'Kategorie',
      'Price label': 'Preislabel',
      'After adding a voucher code, it is automatically advertised in the stream.':
        'Nach dem Hinzufügen eines Gutscheincodes wird dieser automatisch im Stream beworben.',
      'Books are excluded from the voucher': 'Bücher sind vom Gutschein ausgenommen',
      errorKeys: {
        '/dpl/slcs/errors/not_found':
          'Gutschein nicht gefunden. Bitte gebe einen gültigen Gutscheincode ein.',
        '/dpl/slcs/errors/show_ended':
          'Der Stream ist bereits beendet. Gutschein konnte nicht hinzugefügt werden.',
        '/dpl/slcs/errors/voucher_is_not_active':
          'Gutschein bereits abgelaufen. Gutschein konnte nicht hinzugefügt werden.',
        'empty voucher':
          'Speichere oder entferne den Gutschein um fortzufahren. Ein Neuladen der Seite verwirft alle Änderungen.',
      },
    },
    upcomingShowsOverview: {
      'Do you really want to delete this stream?':
        'Möchtest du diesen Stream wirklich löschen? Der Stream wird sofort von der Webseite und der App entfernt.',
      'Upcoming Streams': 'Geplante Streams',
      'Search streams': 'Streams durchsuchen',
      'View stream details': 'Streamdetails anzeigen',
      'Copy base products numbers': 'Artikelnummern kopieren',
      'View in the store': 'Im Shop ansehen',
      sortOptions: {
        'Next stream first': 'Nächster Stream zuerst',
        'Latest stream first': 'Spätester Stream zuerst',
        'Streamer name A-Z': 'Streamer Name A-Z',
        'Streamer name Z-A': 'Streamer Name Z-A',
        'Stream name A-Z': 'Name des Streams A-Z',
        'Stream name Z-A': 'Name des Streams Z-A',
      },
    },
    post: {
      'Create Post': 'Post erstellen',
    },
  },
};

export default t;
