import AddAgencyFormPage from '../agencies/pages/AddAgencyFormPage/AddAgencyFormPage';
import AgenciesOverviewPage from '../agencies/pages/AgenciesOverviewPage/AgenciesOverviewPage';
import AgencyDetailsPage from '../agencies/pages/AgencyDetailsPage/AgencyDetailsPage';
import { Role } from '../auth/role';
import AdminCreatorsOverviewPage from '../creators/pages/AdminCreatorsOverviewPage/AdminCreatorsOverviewPage';
import CreatorDetailsPage from '../creators/pages/CreatorDetailsPage/CreatorDetailsPage';
import AdminCreatePostPage from '../posts/pages/AdminCreatePostPage/AdminCreatePostPage';
import AdminPostsOverviewPage from '../posts/pages/AdminPostsOverviewPage/AdminPostsOverviewPage';
import UpdatePostPage from '../posts/pages/UpdatePostPage/UpdatePostPage';
import CreateShowPage from '../shows/pages/hseEmployee/CreateShowPage/CreateShowPage';
import DuplicateShowPage from '../shows/pages/hseEmployee/DuplicateShowPage/DuplicateShowPage';
import ShowDetailsPage from '../shows/pages/hseEmployee/ShowDetailsPage/ShowDetailsPage';
import UpdateShowPage from '../shows/pages/hseEmployee/UpdateShowPage/UpdateShowPage';
import PastShowsOverviewPage from '../shows/pages/PastShowsOverviewPage/PastShowsOverviewPage';
import ShowModerationPage from '../shows/pages/ShowModerationPage/ShowModerationPage';
import UpcomingShowsOverviewPage from '../shows/pages/UpcomingShowsOverviewPage/UpcomingShowsOverviewPage';
import SurveysPage from '../surveys/pages/SurveysPage';
import { RouteDetails } from './routeDetails';
import routePaths from './routePaths';

export const hseEmployeeRoutes: Record<string, RouteDetails> = {
  agenciesOverview: {
    path: routePaths.hseEmployee.agenciesOverview,
    component: AgenciesOverviewPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  addAgencyForm: {
    path: routePaths.hseEmployee.addAgency,
    component: AddAgencyFormPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  adminAgencyDetails: {
    path: routePaths.hseEmployee.agencyDetails,
    component: AgencyDetailsPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  adminStreamersOverview: {
    path: routePaths.hseEmployee.creatorsOverview,
    component: AdminCreatorsOverviewPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  streamerDetails: {
    path: routePaths.hseEmployee.creatorDetails,
    component: CreatorDetailsPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  upcomingShowOverview: {
    path: routePaths.hseEmployee.upcomingShowsOverview,
    component: UpcomingShowsOverviewPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  pastShowOverview: {
    path: routePaths.hseEmployee.pastShowsOverview,
    component: PastShowsOverviewPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  showDetailsAdmin: {
    path: routePaths.hseEmployee.showDetails,
    component: ShowDetailsPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  showModerationAdmin: {
    path: routePaths.hseEmployee.showModeration,
    component: ShowModerationPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  streamersPostsOverview: {
    path: routePaths.hseEmployee.postsOverview,
    component: AdminPostsOverviewPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  surveysOverview: {
    path: routePaths.hseEmployee.surveysOverview,
    component: SurveysPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  updateStreamerPost: {
    path: routePaths.hseEmployee.updateStreamerPost,
    component: UpdatePostPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  createPost: {
    path: routePaths.hseEmployee.createPost,
    component: AdminCreatePostPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  addShow: {
    path: routePaths.hseEmployee.addShow,
    component: CreateShowPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  editShow: {
    path: routePaths.hseEmployee.editShow,
    component: UpdateShowPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
  duplicateShow: {
    path: routePaths.hseEmployee.duplicateShow,
    component: DuplicateShowPage,
    exact: true,
    protected: true,
    roles: [Role.HSE_EMPLOYEE],
    hideDesktopFooter: true,
  },
};
