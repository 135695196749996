import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../../../layout/PageContainer/PageContainer';
import { clearProductsSelection } from '../../../../products/state/productsSlice';
import theme from '../../../../theme/theme.module.scss';
import { ShowRequest } from '../../../api/showsRequestResponse';
import AdminAddShowForm from '../../../component/AdminAddShowForm/AdminAddShowForm';
import {
  adminCreateNewShow,
  clearShow,
  clearShowDetails,
  selectShowState,
} from '../../../state/showsSlice';

const CreateShowPage = () => {
  const dispatch = useDispatch();
  const show = useSelector(selectShowState);

  useEffect(() => {
    dispatch(clearShow());
    dispatch(clearShowDetails());
    dispatch(clearProductsSelection());
  }, []);

  const onCreateShow = (values: ShowRequest) =>
    dispatch(adminCreateNewShow({ showRequest: values, redirect: false }));

  return (
    <PageContainer
      backgroundColor={theme.whiteLilac}
      pageToRender={<AdminAddShowForm isCreate showToUpdate={show} submitCallback={onCreateShow} />}
      fullHeight
    />
  );
};

export default CreateShowPage;
