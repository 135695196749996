import { Button, EButtonSize, EButtonText, EButtonType } from '@hse24/shared-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Icons from '../../../assets';
import DateFilterInFuture, {
  FutureDateFilters,
} from '../../../components/DateFilter/DateFilterInFuture/DateFilterInFuture';
import { DateRangeWithSelectedFilter } from '../../../components/DateFilter/dateFilterTypes';
import Icon from '../../../components/Icon/Icon';
import { LocalStorageKeys } from '../../../constants/localStorageKeys';
import t from '../../../constants/translation';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import routePaths from '../../../routes/routePaths';
import { sharedStorageService } from '../../../storage/sharedStorageService';
import theme from '../../../theme/theme.module.scss';
import AdminPlannedShowsList from '../../component/AdminPlannedShowsList/AdminPlannedShowsList';
import { StreamedBy } from '../../model/shows';
import {
  selectAdminUpcomingShowsQueryData,
  setAdminUpcomingShowsQueryData,
} from '../../state/showsSlice';
import styles from './UpcomingShowsOverviewPage.module.scss';

const UpcomingShowsOverviewPage = () => {
  const AdminUpcomingShowsOverview = () => {
    const history = useHistory();
    const queryData = useSelector(selectAdminUpcomingShowsQueryData);

    const dispatch = useDispatch();

    const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) =>
      dispatch(
        setAdminUpcomingShowsQueryData({
          dateFrom: dateRangeWithSelectedFilter.startDate,
          dateTo: dateRangeWithSelectedFilter.endDate,
          dateFilter: dateRangeWithSelectedFilter.dateFilter as FutureDateFilters,
        })
      );

    const toggleExcludeEarlyBirdShowsChanged = () =>
      dispatch(
        setAdminUpcomingShowsQueryData({
          excludeEarlyBirds: !(queryData.excludeEarlyBirds ?? false),
        })
      );

    const toggleStreamedByHSEOnly = () => {
      return dispatch(
        setAdminUpcomingShowsQueryData({
          streamedBy: (queryData.streamedBy ?? [])?.includes(StreamedBy.HSE)
            ? []
            : [StreamedBy.HSE],
        })
      );
    };

    const openCreateShow = () => history.push(routePaths.hseEmployee.addShow);
    const showCreateShowButton =
      sharedStorageService.getItem(LocalStorageKeys.upcomingShowsOverviewNewDesign) === 'true';

    return (
      <>
        <div className={styles.header}>
          <span className={styles.title}>{t.admins.upcomingShowsOverview['Upcoming Streams']}</span>
          {showCreateShowButton && (
            <Button
              onClick={openCreateShow}
              className={styles.button}
              textAlign={EButtonText.CENTER}
              size={EButtonSize.SMALL}
            >
              <span className={styles.icon_text}>
                {t.creators['Schedule a stream']}
                <Icon dataTestId="plus-icon" icon={Icons.plusIcon20} width={20} height={20} />
              </span>
            </Button>
          )}
        </div>
        <div className={styles.container}>
          <div className={styles.table_filter}>
            <div>
              <div className={styles.quick_filters_label}>Quick filter</div>
              <div className={styles.quick_filters_wrapper}>
                <Button
                  className={styles.quick_filters_button}
                  size={EButtonSize.SMALL}
                  type={
                    queryData.excludeEarlyBirds === true ? EButtonType.PRIMARY : EButtonType.BLANK
                  }
                  onClick={toggleExcludeEarlyBirdShowsChanged}
                >
                  {t.creators.show.hideEarlyShopping}
                </Button>
                <Button
                  className={styles.quick_filters_button}
                  size={EButtonSize.SMALL}
                  type={
                    queryData.streamedBy?.length == 1 &&
                    queryData.streamedBy.includes(StreamedBy.HSE)
                      ? EButtonType.PRIMARY
                      : EButtonType.BLANK
                  }
                  onClick={toggleStreamedByHSEOnly}
                >
                  {t.creators.show.streamedByHSEOnly}
                </Button>
              </div>
            </div>
            <div>
              <DateFilterInFuture
                onDateFiltersChange={onDateFiltersChange}
                selectedFilter={queryData.dateFilter}
              />
            </div>
          </div>
          <div className={styles.shows_wrapper}>
            <AdminPlannedShowsList />
          </div>
        </div>
      </>
    );
  };
  return (
    <PageContainer backgroundColor={theme.whiteLilac} pageToRender={AdminUpcomingShowsOverview()} />
  );
};

export default UpcomingShowsOverviewPage;
