import { ArrowIconLeft } from '@hse24/shared-components';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Toolbar, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import t from '../../../constants/translation';
import styles from './CreateShowHeader.module.scss';

function CreateShowHeader() {
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <Box className={styles.app_bar}>
        <Toolbar className={styles.tool_bar}>
          <Box className={styles.header}>
            <IconButton onClick={handleGoBack}>
              <CloseIcon />
            </IconButton>
            <Typography className={styles.text} fontWeight="bold">
              {t.creators.show.startShow}
            </Typography>
          </Box>
        </Toolbar>
      </Box>
      <Box className={styles.exit}>
        <IconButton onClick={handleGoBack}>
          <ArrowIconLeft width="16px" height="16px" />
        </IconButton>
        <Typography className={styles.text} fontWeight={400} fontSize={24}>
          {t.creators.show.exitShowCreation}
        </Typography>
      </Box>
    </>
  );
}

export default CreateShowHeader;
