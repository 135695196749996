import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isBefore, subHours } from 'date-fns';
import { RootState } from '../../app/store';
import { Audience, PageableRequest, PageableResponse, Platform } from '../../common/types';
import { FutureDateFilters } from '../../components/DateFilter/DateFilterInFuture/DateFilterInFuture';
import { PastDateFilters } from '../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import { DateFilterTypes } from '../../components/DateFilter/dateFilterTypes';
import { gracePeriodToStartStreamInHours } from '../../constants/show';
import { Pageable } from '../../creators/components/DataGridTable/ServerSideDataGrid';
import { ProductTileInfo } from '../../products/model/productTileInfo';
import { TimeDetails } from '../../utils/dates';
import {
  ArchivedShow,
  CreatorPastShowsResponse,
  CreatorUpcomingShowsResponse,
  ShowDetailsResponse,
  ShowRequest,
  ShowResponse,
  ShowsQueryParams,
  UpcomingShowsWithCreatorsResponse,
} from '../api/showsRequestResponse';
import {
  AddProductToShow,
  ChangeShowVisibility,
  CreatorShows,
  PastShowData,
  PastShowsWithCreatorsResponse,
  ShowDetails,
  ShowUpdatePayload,
  StreamedBy,
  UpcomingShow,
} from '../model/shows';

export interface ShowScheduleProducts {
  selectedProducts: ProductTileInfo[];
  searchResponse: ProductTileInfo[];
}

export interface QueryData<T> {
  sortField: string;
  sortOrder: 'asc' | 'desc';
  searchTerm?: string;
  pageSize: number;
  page: number;
  dateFilter: T;
  dateFrom?: string;
  dateTo?: string;
  audience?: Audience[];
  streamedBy?: StreamedBy[];
  excludeEarlyBirds?: boolean;
}

export type AdminUpcomingShows = UpcomingShowsWithCreatorsResponse & {
  loading: boolean;
  queryData: QueryData<FutureDateFilters>;
};

export type AdminPastShows = PastShowsWithCreatorsResponse & {
  onlyLivePerformance: boolean;
  platform: Platform[];
  loading: boolean;
  queryData: QueryData<PastDateFilters>;
};

export type ShowDetailsState = {
  data: ShowDetails | undefined;
  productDetails: ProductTileInfo[];
  loading: boolean;
  voucherDetails: {
    loading: boolean;
    errorKey?: string;
  };
};

export interface CreatorShowsData {
  data: CreatorShows;
  loading: boolean;
  onlyLivePerformance: boolean;
  shortLink?: string;
  upcoming: UpcomingShow[];
  past: CreatorPastShows;
  archived: ArchivedShow[];
}

export interface CreatorPastShows extends PageableResponse {
  loading: boolean;
  pageable: Pageable;
  shows: PastShowData[];
}

export interface ShowsState {
  show?: ShowResponse;
  creatorShows: CreatorShowsData;
  loading: boolean;
  products: ShowScheduleProducts;
  adminOverview: {
    upcoming: AdminUpcomingShows;
    past: AdminPastShows;
  };
  showDetails: ShowDetailsState;
  highLightProductState: HighLightProductState;
  approvedTags: string[];
  availableShowTimeSlots: {
    isLoading: boolean;
    slots: TimeDetails[];
  };
}

export const initialCreatorPastShows: CreatorPastShows = {
  loading: false,
  shows: [],
  hits: 0,
  pages: 0,
  pageable: {
    sortField: 'startedAt',
    sortOrder: 'desc',
    pageSize: 5,
    page: 0,
  },
};

export const initialUpcomingShowsQueryData: QueryData<FutureDateFilters> = {
  sortField: 'scheduledAt',
  sortOrder: 'asc',
  pageSize: 25,
  page: 0,
  dateFilter: DateFilterTypes.NEXT_28_DAYS,
  dateFrom: undefined,
  dateTo: undefined,
  audience: [Audience.CLASSIC],
  streamedBy: [],
};

export const initialUpcomingAdminOverview: AdminUpcomingShows = {
  loading: false,
  shows: [],
  hits: 0,
  pages: 0,
  queryData: initialUpcomingShowsQueryData,
};

export const initialPastShowsQueryData: QueryData<PastDateFilters> = {
  ...initialUpcomingShowsQueryData,
  pageSize: 10,
  dateFilter: DateFilterTypes.MONTH,
  sortField: 'startedAt',
  sortOrder: 'desc',
};

export const initialPastAdminOverview: AdminPastShows = {
  onlyLivePerformance: false,
  platform: [],
  loading: false,
  shows: [],
  hits: 0,
  pages: 0,
  queryData: initialPastShowsQueryData,
};

export const showsInitialState: ShowsState = {
  creatorShows: {
    data: { past: [], upcoming: [] },
    loading: true,
    onlyLivePerformance: false,
    upcoming: [],
    past: initialCreatorPastShows,
    archived: [],
  },
  products: {
    selectedProducts: [],
    searchResponse: [],
  },
  loading: true,
  show: undefined,
  adminOverview: {
    upcoming: initialUpcomingAdminOverview,
    past: initialPastAdminOverview,
  },
  showDetails: {
    data: undefined,
    productDetails: [],
    loading: false,
    voucherDetails: {
      loading: false,
    },
  },
  highLightProductState: {
    highlightedProducts: [],
    isError: false,
    message: '',
  },
  approvedTags: [],
  availableShowTimeSlots: {
    isLoading: false,
    slots: [],
  },
};

export const getCreatorPastShows = createAction('shows/getCreatorPastShows');
export const getCreatorUpcomingShows = createAction<PageableRequest | undefined>(
  'shows/getCreatorUpcomingShows'
);
export const getCreatorArchivedShows = createAction('shows/getCreatorArchivedShows');
export const createNewShow = createAction<ShowRequest>('shows/createNewShow');
export const updateShow = createAction<ShowUpdatePayload>('shows/updateShow');
export const fetchShow = createAction<string>('shows/fetchShow');
export const getApprovedTags = createAction('tags/getApprovedTags');

export const deleteShow = createAction<string>('show/delete');
export const deleteShowByAdmin =
  createAction<{ showId: string; redirect?: boolean }>('show/deleteByAdmin');

export const checkForShowEndedTracking = createAction('show/checkForShowEndedTracking');

export const fetchAmaMessages = createAction<string>('show/fetchAmaMessages');

export const attachVoucherToShow = createAction<{ showId: string; voucherCode: string }>(
  'show/attachVoucherToShow'
);

export const detachVoucherFromShow = createAction<{ showId: string }>('show/detachVoucherToShow');

export const deleteExpiredShow = createAction<string>('show/deleteExpiredShow');
export const updateStreamedBy =
  createAction<{ showId: string; streamedBy: StreamedBy }>('show/updateStreamedBy');
export const fetchAvailableShowTimeSlot = createAction<Date>('show/fetchAvailableShowTimeSlot');

export const adminCreateNewShow = createAction<{
  showRequest: ShowRequest;
  redirect: boolean;
}>('shows/adminCreateNewShow');
export const adminUpdateShow = createAction<ShowUpdatePayload>('shows/adminUpdateShow');

export interface HighLightProductState {
  highlightedProducts: ProductTileInfo[];
  isError?: boolean;
  message?: string;
}

export interface HighlightProduct {
  baseProductNos: string[];
  showId: string;
}

export interface ShowScheduleProductsParams {
  query: string;
  audience: Audience[];
}

export const highLightProducts = createAction<HighlightProduct>('shows/highLightProducts');
export const setShowsOnlyLivePerformanceAction = createAction<boolean>(
  'shows/setShowsOnlyLivePerformanceAction'
);
export const setAdminPastShowsOnlyLivePerformanceAction = createAction<boolean>(
  'shows/setAdminPastShowsOnlyLivePerformanceAction'
);
export const fetchShowDetails = createAction<string>('shows/fetchShowDetails');

export const searchForShowScheduleProducts = createAction<ShowScheduleProductsParams>(
  'shows/searchForScheduleShowProducts'
);

export const onSelectProductForShowSchedule = createAction<ProductTileInfo>(
  'shows/onSelectProductForShowSchedule'
);
export const onRemoveProductForShowSchedule = createAction<ProductTileInfo>(
  'shows/onRemoveProductForShowSchedule'
);

export const navigateToCreateShowPage = createAction('shows/navigateToCreateShowPage');
export const getShowShorterLink = createAction<string>('shows/getShowShorterLink');
export const closeShowShareModal = createAction('shows/closeShowShareModal');
export const firstShowCreated = createAction<{ title: string; id: string }>('shows/showCreated');
export const endShow = createAction<ShowDetails>('shows/endShow');
export const delayDispatchOfShowCreatedEvent = createAction<{ title: string; id: string }>(
  'shows/delayDispatchOfShowCreatedEvent'
);
export const queryAdminUpcomingShows = createAction<ShowsQueryParams>(
  'shows/queryAdminUpcomingShows'
);
export const queryAdminPastShows = createAction<ShowsQueryParams>('shows/queryAdminPastShows');
export const changeShowVisibility = createAction<ChangeShowVisibility>(
  'shows/changeShowVisibility'
);
export const addProductToShow = createAction<AddProductToShow>('shows/addProductToShow');

function sortNewProductsOnTop(
  previousProductDetails: ProductTileInfo[],
  newProductDetails: ProductTileInfo[]
) {
  const oldProductsNo = previousProductDetails.map(it => it.baseProductNo);
  const newProducts = newProductDetails.filter(it => !oldProductsNo.includes(it.baseProductNo));
  const oldProducts = newProductDetails.filter(it => oldProductsNo.includes(it.baseProductNo));

  return [...newProducts, ...oldProducts];
}

export const showsSlice = createSlice({
  name: 'shows',
  initialState: showsInitialState,
  reducers: {
    setCreatorShowsLoading: (state, action: PayloadAction<boolean>) => {
      state.creatorShows.loading = action.payload;
    },
    setCreatorUpcomingShows: (state, action: PayloadAction<CreatorUpcomingShowsResponse>) => {
      state.creatorShows.upcoming = action.payload.shows;
      state.creatorShows.loading = false;
    },
    setCreatorArchivedShows: (state, action: PayloadAction<ArchivedShow[]>) => {
      state.creatorShows.archived = action.payload;
    },
    removeFromCreatorArchivedShow: (state, action: PayloadAction<string>) => {
      state.creatorShows.archived = state.creatorShows.archived.filter(
        e => e.id !== action.payload
      );
    },
    removeFromCreatorUpcomingShows: (state, action: PayloadAction<string>) => {
      state.creatorShows.upcoming = state.creatorShows.upcoming.filter(
        e => e.id !== action.payload
      );
      state.creatorShows.loading = false;
    },
    setCreatorPastShows: (state, action: PayloadAction<CreatorPastShowsResponse>) => {
      state.creatorShows.past = {
        ...state.creatorShows.past,
        hits: action.payload.hits,
        pages: action.payload.pages,
        shows: action.payload.shows,
      };
    },
    setCreatorPastShowsLoading: (state, action: PayloadAction<boolean>) => {
      state.creatorShows.past.loading = action.payload;
    },
    setCreatorPastShowsPageable: (state, action: PayloadAction<Pageable>) => {
      state.creatorShows.past.pageable = action.payload;
    },
    setShowScheduleProducts: (state, action: PayloadAction<ProductTileInfo>) => {
      state.products.selectedProducts = [...state.products.selectedProducts, action.payload];
    },
    replaceShowScheduleProducts: (state, action: PayloadAction<ProductTileInfo[]>) => {
      state.products.selectedProducts = action.payload;
    },
    setShowScheduleSearchResponse: (state, action: PayloadAction<ProductTileInfo[]>) => {
      state.products.searchResponse = action.payload;
    },
    setShow: (state, action: PayloadAction<ShowResponse>) => {
      state.show = action.payload;
      state.loading = false;
    },
    clearShow: state => {
      state.show = undefined;
    },
    setShowsOnlyLivePerformance: (state, action: PayloadAction<boolean>) => {
      state.creatorShows.onlyLivePerformance = action.payload;
    },
    clearAllProducts: state => {
      state.products.selectedProducts = [];
      state.products.searchResponse = [];
    },
    setShortLinkSuccess: (state, action: PayloadAction<string>) => {
      state.creatorShows.shortLink = action.payload;
    },
    closeShareModal: state => {
      state.creatorShows.shortLink = undefined;
    },
    setShowDetails: (state, action: PayloadAction<ShowDetailsResponse>) => {
      state.showDetails = {
        ...state.showDetails,
        data: action.payload,
        loading: false,
        voucherDetails: {
          loading: false,
          errorKey: undefined,
        },
      };
    },
    clearShowDetails: state => {
      state.showDetails = showsInitialState.showDetails;
    },
    setShowProductDetails: (state, action: PayloadAction<ProductTileInfo[]>) => {
      state.showDetails.productDetails = sortNewProductsOnTop(
        state.showDetails.productDetails,
        action.payload
      );
    },
    setShowDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.showDetails.loading = action.payload;
    },
    setShowDetailsVoucher: (
      state,
      action: PayloadAction<{ loading: boolean; errorKey?: string }>
    ) => {
      if (state.showDetails.voucherDetails != null) {
        state.showDetails.voucherDetails.loading = action.payload.loading;
        state.showDetails.voucherDetails.errorKey = action.payload.errorKey;
      }
    },
    setAdminUpcomingShows: (state, action: PayloadAction<UpcomingShowsWithCreatorsResponse>) => {
      state.adminOverview.upcoming.shows = action.payload.shows;
      state.adminOverview.upcoming.hits = action.payload.hits;
      state.adminOverview.upcoming.pages = action.payload.pages;
    },
    setAdminUpcomingShowVisibility: (
      state,
      action: PayloadAction<{ showId: string; isPublished: boolean }>
    ) => {
      const showIndex = state.adminOverview.upcoming.shows.findIndex(
        s => s.id === action.payload.showId
      );
      state.adminOverview.upcoming.shows[showIndex].isPublished = action.payload.isPublished;
    },

    setAdminPastShows: (state, action: PayloadAction<PastShowsWithCreatorsResponse>) => {
      state.adminOverview.past.shows = action.payload.shows;
      state.adminOverview.past.hits = action.payload.hits;
      state.adminOverview.past.pages = action.payload.pages;
    },
    setAdminPastShowsOnlyLivePerformance: (state, action: PayloadAction<boolean>) => {
      state.adminOverview.past.onlyLivePerformance = action.payload;
    },
    setAdminPastShowsPlatform: (state, action: PayloadAction<Platform[]>) => {
      state.adminOverview.past.platform = action.payload;
    },
    setAdminUpcomingShowsLoading: (state, action: PayloadAction<boolean>) => {
      state.adminOverview.upcoming.loading = action.payload;
    },
    setAdminUpcomingShowsQueryData: (
      state,
      action: PayloadAction<Partial<QueryData<FutureDateFilters>>>
    ) => {
      state.adminOverview.upcoming.queryData = {
        ...state.adminOverview.upcoming.queryData,
        ...action.payload,
      };
    },
    setAdminPastShowsLoading: (state, action: PayloadAction<boolean>) => {
      state.adminOverview.past.loading = action.payload;
    },
    setAdminPastShowsQueryData: (
      state,
      action: PayloadAction<Partial<QueryData<PastDateFilters>>>
    ) => {
      state.adminOverview.past.queryData = {
        ...state.adminOverview.past.queryData,
        ...action.payload,
      };
    },
    pushHighLightedProductFailure: (state, action: PayloadAction<string>) => {
      state.highLightProductState.isError = true;
      state.highLightProductState.message = action.payload;
    },
    pushHighLightedProductSuccess: state => {
      state.highLightProductState.isError = false;
      state.highLightProductState.message = undefined;
    },
    addHighlightedProduct: (state, action: PayloadAction<ProductTileInfo[]>) => {
      state.highLightProductState.highlightedProducts = action.payload;
    },
    setShowPublished: (state, action: PayloadAction<boolean>) => {
      if (state.showDetails.data) {
        state.showDetails.data.isPublished = action.payload;
      }
    },
    setApprovedTags: (state, action: PayloadAction<string[]>) => {
      state.approvedTags = action.payload;
    },
    setShowTimeSlotsIsLoading: (state, action: PayloadAction<boolean>) => {
      state.availableShowTimeSlots.isLoading = action.payload;
    },
    setAvailableShowTimeSlots: (state, action: PayloadAction<TimeDetails[]>) => {
      state.availableShowTimeSlots.slots = action.payload;
    },
  },
});

export const {
  setCreatorShowsLoading,
  setCreatorUpcomingShows,
  setCreatorArchivedShows,
  removeFromCreatorArchivedShow,
  removeFromCreatorUpcomingShows,
  setCreatorPastShows,
  setCreatorPastShowsPageable,
  setCreatorPastShowsLoading,
  setShowScheduleProducts,
  setShowScheduleSearchResponse,
  replaceShowScheduleProducts,
  setShow,
  clearShow,
  setShowsOnlyLivePerformance,
  clearAllProducts,
  setShortLinkSuccess,
  closeShareModal,
  setShowDetails,
  clearShowDetails,
  setShowProductDetails,
  setAdminUpcomingShows,
  setAdminUpcomingShowVisibility,
  setAdminPastShows,
  setAdminUpcomingShowsLoading,
  setAdminPastShowsLoading,
  setShowDetailsLoading,
  setShowDetailsVoucher,
  setAdminUpcomingShowsQueryData,
  setAdminPastShowsQueryData,
  setAdminPastShowsOnlyLivePerformance,
  setAdminPastShowsPlatform,
  pushHighLightedProductFailure,
  pushHighLightedProductSuccess,
  addHighlightedProduct,
  setShowPublished,
  setApprovedTags,
  setAvailableShowTimeSlots,
  setShowTimeSlotsIsLoading,
} = showsSlice.actions;
const selectShowsState = (state: RootState) => state[showsSlice.name];

export const selectCreatorShows = createSelector(
  selectShowsState,
  state => state.creatorShows.data
);

export const selectCreatorShowsStartingSoon = createSelector(selectShowsState, state => {
  // Remove shows that should already be started (including a grace period of 3h)
  const filteredShows = state.creatorShows.data.upcoming.filter(show =>
    isBefore(subHours(Date.now(), gracePeriodToStartStreamInHours), new Date(show.scheduledAt))
  );
  return filteredShows.length >= 3 ? filteredShows.slice(0, 3) : filteredShows;
});

export const selectShowState = createSelector(selectShowsState, state => state.show);
export const selectShowIsBeingLoaded = createSelector(selectShowsState, state => state.loading);
export const selectShowsAreBeingLoaded = createSelector(
  selectShowsState,
  state => state.creatorShows.loading
);
export const selectShowsOnlyLivePerformanceFlag = createSelector(
  selectShowsState,
  state => state.creatorShows.onlyLivePerformance
);
export const selectUpcomingShows = createSelector(selectCreatorShows, state => state.upcoming);

export const selectPastShows = createSelector(selectCreatorShows, state => state.past);

export const selectSelectedProducts = (state: RootState) =>
  state[showsSlice.name].products.selectedProducts;

export const selectSearchProducts = (state: RootState) =>
  state[showsSlice.name].products.searchResponse;

export const selectCreatorUpcomingShows = (state: RootState) =>
  state[showsSlice.name].creatorShows.upcoming;

export const selectCreatorPastShows = (state: RootState) =>
  state[showsSlice.name].creatorShows.past;

export const selectCreatorPastShowsPageable = (state: RootState) =>
  state[showsSlice.name].creatorShows.past.pageable;

export const selectCreatorArchivedShows = (state: RootState) =>
  state[showsSlice.name].creatorShows.archived;

export const selectShortLink = (state: RootState) => state[showsSlice.name].creatorShows.shortLink;

export const hasStreamerCreatedExactlyOneShow = (state: RootState) =>
  [...state.shows.creatorShows.data.past, ...state.shows.creatorShows.data.upcoming].length === 1;

export const selectAdminShowsState = createSelector(
  selectShowsState,
  state => state?.adminOverview
);
export const selectAdminUpcomingShows = createSelector(
  selectAdminShowsState,
  state => state.upcoming
);
export const selectAdminPastShows = createSelector(selectAdminShowsState, state => state.past);
export const selectAdminUpcomingShowsQueryData = createSelector(
  selectAdminUpcomingShows,
  state => state.queryData
);
export const selectAdminPastShowsQueryData = createSelector(
  selectAdminPastShows,
  state => state.queryData
);
export const selectAdminPastShowsOnlyLivePerformance = createSelector(
  selectAdminPastShows,
  state => state.onlyLivePerformance
);

export const selectAdminPastShowsPlatform = createSelector(
  selectAdminPastShows,
  state => state.platform
);

export const selectShowDetails = (state: RootState) => state.shows.showDetails;
export const selectHighlightedProducts = (state: RootState) =>
  state.shows.highLightProductState.highlightedProducts;
export const selectShowDetailsData = createSelector(selectShowDetails, state => state.data);
export const selectShowDetailsStreamedBy = createSelector(
  selectShowDetails,
  state => state.data?.streamedBy
);
export const selectShowDetailsVoucher = createSelector(
  selectShowDetails,
  state => state.voucherDetails
);
export const selectShowDetailsLoading = createSelector(selectShowDetails, state => state.loading);
export const selectShowProductDetails = createSelector(
  selectShowDetails,
  state => state.productDetails
);
export const selectApprovedTags = (state: RootState) => state.shows.approvedTags;
export const selectAvailableShowTimeSlots = (state: RootState) =>
  state.shows.availableShowTimeSlots.slots;

export const selectShowTimeSlotsIsLoading = createSelector(
  selectShowsState,
  state => state.availableShowTimeSlots.isLoading
);

export default showsSlice.reducer;
