import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as UploadIcon } from './arrow-up-from-bracket.svg';
import { ReactComponent as CalendarIcon } from './calendar.svg';
import { ReactComponent as CheckIcon } from './check-icon.svg';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import { ReactComponent as ClothesHanger } from './clothes-hanger.svg';
import { ReactComponent as CopyIcon } from './copy-icon.svg';
import { ReactComponent as Crop } from './crop.svg';
import { ReactComponent as ExternalLinkIcon } from './external-link.svg';
import { ReactComponent as EyesViewOffInvisibleIcon } from './eyes-view-off-invisible.svg';
import { ReactComponent as EyesViewOnVisibleIcon } from './eyes-view-on-visible.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as FileSize } from './fileSize.svg';
import { ReactComponent as HomeIcon } from './home-icon.svg';
import { ReactComponent as LiveIcon } from './icon-play.svg';
import { ReactComponent as LogoutIcon } from './logout.svg';
import { ReactComponent as MarketplaceIcon } from './marketplace-icon.svg';
import { ReactComponent as MoreIcon } from './more-horizontal-icon.svg';
import { ReactComponent as PencilIcon } from './pencil-icon.svg';
import { ReactComponent as PersonIcon } from './personIcon.svg';
import { ReactComponent as Photo } from './photo.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as PlusIcon20 } from './plus-icon-20.svg';
import { ReactComponent as PlusIcon } from './plus-icon.svg';
import { ReactComponent as SearchIcon } from './search-icon.svg';
import { ReactComponent as SettingsIcon } from './settings-icon.svg';
import { ReactComponent as ShieldIcon } from './shield.svg';
import { ReactComponent as BasketIcon } from './shoppingbag.svg';
import { ReactComponent as StarWithCircle } from './star-with-circle.svg';
import { ReactComponent as UserIcon } from './user-icon.svg';
import { ReactComponent as VerifiedUser } from './verified-user.svg';
import { ReactComponent as VideoCameraWithCircle } from './video-camera-with-circle.svg';
import { ReactComponent as VideoCamera } from './video-camera.svg';
import { ReactComponent as VoucherIcon } from './voucher-icon.svg';

export type SvgIcon = FunctionComponent<SVGProps<SVGSVGElement>>;

const Icons: Record<string, SvgIcon> = {
  basketIcon: BasketIcon,
  calendarIcon: CalendarIcon,
  checkIcon: CheckIcon,
  closeIcon: CloseIcon,
  clothesHanger: ClothesHanger,
  crop: Crop,
  externalLinkIcon: ExternalLinkIcon,
  eyesViewOffInvisibleIcon: EyesViewOffInvisibleIcon,
  eyesViewOnVisibleIcon: EyesViewOnVisibleIcon,
  file: File,
  fileSize: FileSize,
  homeIcon: HomeIcon,
  liveIcon: LiveIcon,
  logoutIcon: LogoutIcon,
  marketplaceIcon: MarketplaceIcon,
  moreIcon: MoreIcon,
  pencilIcon: PencilIcon,
  personIcon: PersonIcon,
  photo: Photo,
  play: Play,
  plusIcon20: PlusIcon20,
  plusIcon: PlusIcon,
  searchIcon: SearchIcon,
  shieldIcon: ShieldIcon,
  starWithCircle: StarWithCircle,
  uploadIcon: UploadIcon,
  verifiedUser: VerifiedUser,
  videoCamera: VideoCamera,
  videoCameraWithCircle: VideoCameraWithCircle,
  userIcon: UserIcon,
  voucherIcon: VoucherIcon,
  settingsIcon: SettingsIcon,
  copyIcon: CopyIcon,
};

export default Icons;
