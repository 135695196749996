import {
  Button,
  EButtonSize,
  EButtonType,
  EInputTypes,
  Error,
  ETag,
  Text,
} from '@hse24/shared-components';
import { Grid, Typography } from '@mui/material';
import { format, subMinutes } from 'date-fns';
import dayjs from 'dayjs';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import t from '../../../../src/constants/translation';
import Icons from '../../../assets';
import CreatorsSearchDropdown from '../../../components/CreatorsSearchDropdown/CreatorsSearchDropdown';
import FormHeader from '../../../components/FormHeader/FormHeader';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import TextField from '../../../components/TextField/TextField';
import { LocalStorageKeys } from '../../../constants/localStorageKeys';
import { selectProductSelection } from '../../../products/state/productsSlice';
import routePaths from '../../../routes/routePaths';
import { useShowImages } from '../../../shared/useShowImages';
import { sharedStorageService } from '../../../storage/sharedStorageService';
import { toBerlinTimeZone } from '../../../utils/dates';
import { ShowResponse } from '../../api/showsRequestResponse';
import { ImageType, ScheduleShowData, ShowCoverSelectionMetadata } from '../../model/shows';
import { selectShowDetailsData } from '../../state/showsSlice';
import { mapCurtainsToShowImages } from '../../utils/showImages';
import useFormChanged from '../../utils/useFormChanged';
import NewShowDetails from '../NewShowDetails/NewShowDetails';
import showSchema from '../ScheduleShowForm/ShowSchema/ShowSchema';
import DateSelector from '../ScheduleShowForm/ShowStartTimePicker/DateSelector/DateSelector';
import ShowStartTimePicker from '../ScheduleShowForm/ShowStartTimePicker/ShowStartTimePicker';
import ShowCoverSelection from '../ShowCoverSelection/ShowCoverSelection';
import ShowProductsSelection from '../ShowProductsSelection/ShowProductsSelection';
import styles from './AdminAddShowForm.module.scss';

export interface AdminShowData {
  title?: string | null;
  preview?: string;
  baseProductsNo: string[];
  scheduledStartAt?: string;
  salesTopic?: string;
  creatorId?: string;
}

const showCoverSelectionMetadata: ShowCoverSelectionMetadata = {
  displayTitles: false,
  icon: Icons.videoCamera,
  title: 'Vorschaubild',
  subTitle:
    'Du kannst ein Bild hochladen, das den Followern zeigt, was in ' +
    'deinem Stream passiert. Ein gutes Vorschaubild fällt auf und erzeugt Interesse bei den Zuschauern.',
};

export interface AdminAddShowFormProps {
  isCreate: boolean;
  showToUpdate?: ShowResponse;
  showToDuplicate?: ShowResponse;
  submitCallback: (values: ScheduleShowData) => void;
}

const AdminAddShowForm = ({
  isCreate,
  submitCallback,
  showToUpdate,
  showToDuplicate,
}: AdminAddShowFormProps) => {
  const nowSub5min = useMemo(() => subMinutes(new Date(), 5), []);
  const showToBeFilled = showToUpdate || showToDuplicate;
  const history = useHistory();
  const { selectedProducts } = useSelector(selectProductSelection);
  const showDetails = useSelector(selectShowDetailsData);

  const [showImages] = useShowImages(mapCurtainsToShowImages(showToUpdate?.curtains));
  const [title, setTitle] = useState('');
  const [creatorId, setCreatorId] = useState('');
  const [salesTopic, setSalesTopic] = useState('');

  const [preview, setPreview] = useState('');
  const [scheduledAt, setScheduledAt] = useState<Date | undefined>(undefined);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const getScheduleAt = () => scheduledAt?.toISOString() ?? '';
  const hasFormChanged = useFormChanged<AdminShowData>(
    {
      title,
      preview,
      baseProductsNo: selectedProducts.map(p => p.baseProductNo),
      scheduledStartAt: getScheduleAt(),
      creatorId,
      salesTopic,
    },
    showToUpdate
      ? {
          title: showToUpdate.title,
          preview: mapCurtainsToShowImages(showToUpdate?.curtains)?.previewImageUrl,
          baseProductsNo: showToUpdate.products?.map(p => p.baseProductNo) || [],
          scheduledStartAt: dayjs(showToUpdate.scheduledAt).toDate().toISOString(),
          creatorId: showToUpdate?.creator?.id ?? '',
          salesTopic: showToUpdate.salesTopic ?? '',
        }
      : null
  );

  useEffect(() => {
    setTitle(showToBeFilled?.title ?? '');
    setCreatorId(showToBeFilled?.creator?.id ?? '');
    setSalesTopic(showToBeFilled?.salesTopic ?? '');
    setScheduledAt(
      showToUpdate?.scheduledAt ? dayjs(showToUpdate.scheduledAt).toDate() : undefined
    );
    setPreview(mapCurtainsToShowImages(showToBeFilled?.curtains)?.previewImageUrl ?? '');
  }, [showToBeFilled]);

  const initialValues: ScheduleShowData & { productSearch: string } = {
    header: showToBeFilled?.header ?? '',
    title: showToBeFilled?.title ?? '',
    tags: [],
    scheduledStartAt: showToBeFilled?.scheduledAt ?? '',
    baseProductsNo: [], // Will be taken from the selector in the child component
    preview: showImages.previewImageUrl
      ? {
          imageType: ImageType.PREVIEW,
          fileExtension: 'png',
          imageUrl: showImages.previewImageUrl,
          changed: false,
        }
      : undefined,
    productSearch: '',
    salesTopic: showToBeFilled?.salesTopic ?? '',
  };

  const [shouldDisplayHeaderField, setShouldDisplayHeaderField] = useState(false);

  useEffect(() => {
    const displayHeaderFieldItem = sharedStorageService.getItem(
      LocalStorageKeys.displayHeaderField
    );
    setShouldDisplayHeaderField(displayHeaderFieldItem === 'true');
  }, []);

  const mapFormValuesToScheduleShowData = (
    values: ScheduleShowData & { productSearch: string }
  ): ScheduleShowData => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { productSearch, ...rest } = values;
    rest.scheduledStartAt = scheduledAt ? toBerlinTimeZone(scheduledAt).toISOString() : '';
    rest.creatorId = creatorId;
    return rest;
  };

  const handleCancel = () => history.push(routePaths.hseEmployee.upcomingShowsOverview);

  const renderErrorMsg = (message: string) => {
    return <Error className={styles.error_message} error={message} />;
  };

  const hideActionButtons = (showToUpdate || isFormSubmitted) && !hasFormChanged;

  return (
    <>
      <FormHeader title="Streams" />
      <Formik
        initialValues={initialValues}
        validationSchema={showSchema}
        onSubmit={values => {
          setIsFormSubmitted(true);
          submitCallback(mapFormValuesToScheduleShowData(values));
        }}
        initialTouched={{
          scheduledStartAt: false,
          title: false,
          baseProductsNo: false,
          salesTopic: false,
        }}
        initialErrors={{ baseProductsNo: '' }}
      >
        {({ setFieldTouched, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <div className={styles.wrapper} data-testid="classic-show-form">
                <Grid className={styles.container} container spacing={2}>
                  <Grid container item xs={12} md={7} spacing={4}>
                    <Grid item xs={12}>
                      <Text.Body tag={ETag.DIV} className={styles.title}>
                        Streamer*
                      </Text.Body>
                      <CreatorsSearchDropdown creatorId={creatorId} setCreatorId={setCreatorId} />
                      {!creatorId &&
                        isSubmitClicked &&
                        renderErrorMsg(t.validation['Please select a creator'])}
                    </Grid>
                    <Grid item xs={12}>
                      <Text.Body tag={ETag.DIV} className={styles.title}>
                        Geplanter Stream-Start*
                      </Text.Body>
                      <ShowStartTimePicker
                        scheduledStartAt={getScheduleAt()}
                        onClick={() => setIsDateSelectorOpen(true)}
                        hasError={
                          errors.scheduledStartAt != undefined && touched.scheduledStartAt == true
                        }
                        isButton
                      />
                      <div className={styles.date_Selector}>
                        <TextField
                          data-testid="scheduledStartAt"
                          fieldName="scheduledStartAt"
                          value={format(scheduledAt ?? nowSub5min, 'dd.MM.yyyy HH:mm')}
                          label="Startzeit"
                        />
                      </div>
                      <ErrorMessage name="scheduledStartAt">
                        {() => renderErrorMsg(t.validation['Please set a time'])}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                      <Text.Body tag={ETag.DIV} className={styles.title}>
                        Stream-Titel*
                      </Text.Body>
                      {shouldDisplayHeaderField && (
                        <TextField
                          fieldName="header"
                          value={showToBeFilled?.header}
                          label="Header"
                        />
                      )}
                      <TextField
                        fieldName="title"
                        value={showToBeFilled?.title}
                        onChange={value => setTitle(value ?? '')}
                        label="Maximal 60 Zeichen"
                      />
                      {errors.title !== undefined &&
                        isSubmitClicked &&
                        renderErrorMsg(t.validation['Please enter a title'])}
                    </Grid>
                    <Grid item xs={12}>
                      <Text.Body tag={ETag.DIV} className={styles.title}>
                        Produkte*
                      </Text.Body>
                      <ShowProductsSelection
                        hasError={
                          errors.baseProductsNo !== undefined &&
                          isSubmitClicked &&
                          (selectedProducts == undefined || selectedProducts.length == 0)
                        }
                        isButton
                        isAdmin
                      />
                      {errors.baseProductsNo !== undefined &&
                        isSubmitClicked &&
                        (selectedProducts == undefined || selectedProducts.length == 0) && (
                          <ErrorMessage name="baseProductsNo">
                            {() =>
                              renderErrorMsg(t.validation['Please include one or more products'])
                            }
                          </ErrorMessage>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      <Text.Body tag={ETag.DIV} className={styles.title}>
                        Sales Thema/Kampagne
                      </Text.Body>
                      <TextField
                        fieldName="salesTopic"
                        value={showToBeFilled?.salesTopic}
                        onChange={value => setSalesTopic(value ?? '')}
                        label="Thema oder Basiswording"
                      />
                      <Text.BodySmall className={styles.sales_topic_hint}>
                        Nicht für Kunden sichtbar
                      </Text.BodySmall>
                    </Grid>
                    {isDateSelectorOpen && (
                      <DateSelector
                        title={t.creators.show.DateSelectorTitle}
                        isDialogOpen={isDateSelectorOpen}
                        closeDialog={() => {
                          setIsDateSelectorOpen(false);
                        }}
                        plannedAt={scheduledAt ?? nowSub5min}
                        updatePlannedAt={(date: Date) => {
                          setScheduledAt(date);
                          setFieldTouched('scheduledStartAt', true);
                          setFieldValue('scheduledStartAt', format(date, 'dd.MM.yyyy HH:mm'));
                        }}
                      />
                    )}
                    {!hideActionButtons && (
                      <Grid container item xs={12} className={styles.desktop_actions}>
                        <Button
                          onClick={handleCancel}
                          type={EButtonType.BLANK}
                          inputType={EInputTypes.button}
                        >
                          <Typography fontWeight={700}> {t.common.Cancel}</Typography>
                        </Button>
                        <Button
                          type={EButtonType.PRIMARY}
                          inputType={EInputTypes.submit}
                          onClick={() => {
                            setIsSubmitClicked(true);
                          }}
                          data-testid="submit-button"
                        >
                          {isCreate ? (
                            <Typography fontWeight={700}>Stream erstellen</Typography>
                          ) : (
                            <Typography fontWeight={700}>Stream ändern</Typography>
                          )}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Grid item xs={12}>
                      <ShowCoverSelection
                        hasError={
                          (errors.preview !== undefined || preview == '') &&
                          showImages.previewImageUrl == undefined &&
                          isSubmitClicked
                        }
                        fieldName="preview"
                        imageType={ImageType.PREVIEW}
                        defaultImage={showImages.previewImageUrl}
                        metadata={showCoverSelectionMetadata}
                        onChange={imageUrl => setPreview(imageUrl ?? '')}
                      />

                      {(errors.preview !== undefined || preview == '') &&
                        isSubmitClicked &&
                        showImages.previewImageUrl == undefined &&
                        renderErrorMsg(t.validation['Please insert a preview image'])}
                    </Grid>
                  </Grid>
                </Grid>
                {!hideActionButtons && (
                  <Grid container className={styles.actions} gap={2}>
                    <Button
                      type={EButtonType.BLANK}
                      onClick={handleCancel}
                      size={EButtonSize.MEDIUM}
                      inputType={EInputTypes.button}
                    >
                      <Typography fontWeight={700}> {t.common.Cancel}</Typography>
                    </Button>
                    <Button
                      type={EButtonType.PRIMARY}
                      inputType={EInputTypes.submit}
                      size={EButtonSize.MEDIUM}
                      onClick={() => {
                        setIsSubmitClicked(true);
                      }}
                      data-testid="submit-button"
                    >
                      {isCreate ? (
                        <Typography fontWeight={700}>Stream erstellen</Typography>
                      ) : (
                        <Typography fontWeight={700}>Stream ändern</Typography>
                      )}
                    </Button>
                  </Grid>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      {!!showDetails && showToDuplicate == undefined && (
        <NewShowDetails showDetails={showDetails} />
      )}
      <RouteLeavingGuard blockNavigation={hasFormChanged} navigationPermitted={isFormSubmitted} />
    </>
  );
};

export default AdminAddShowForm;
