import { SvgIcon } from '../../assets';
import { Audience, FloorSale, PageableResponse } from '../../common/types';
import { CreatorImageType, CreatorRole } from '../../creators/model/creator';
import { ProductPrice } from '../../products/model/price';
import { ShowRequest } from '../api/showsRequestResponse';
import { Voucher, VoucherResponse } from './voucher';

export enum Currency {
  EUR = 'EUR',
}

export interface PastShowsSales {
  total: FloorSale;
  perVisitor: FloorSale;
  perMinute: FloorSale;
  iosFloorSales: FloorSale;
  androidFloorSales: FloorSale;
  webFloorSales: FloorSale;
  appFloorSaleTotal: FloorSale;
}

export interface ViewsOverview {
  helloAppViews: number;
  classicAppViews: number;
  classicWebViews: number;
  classicIosViews: number;
  classicAndroidViews: number;
  classicViewsTotal: number;
  views: number;
}

export interface ViewersOverview {
  helloAppViewers: number;
  classicAppViewers: number;
  classicWebViewers: number;
  classicIosViewers: number;
  classicAndroidViewers: number;
  classicViewersTotal: number;
  viewers: number;
}
export interface PlatFormOrdersOverview {
  webOrdersCount: number;
  iosOrdersCount: number;
  androidOrdersCount: number;
}
export interface ShowTotalAndLivePerformance {
  sales: PastShowsSales;
  viewersOverview: ViewersOverview;
  viewsOverview: ViewsOverview;
  ordersOverview: PlatFormOrdersOverview;
  likes: number;
  shares: number;
  chatMessages: number;
  timeWatched: {
    seconds: number;
    perView: number;
    perViewer: number;
  };
}

export type PastShow = PastShowData & PastShowAuxData;

export interface PastShowData {
  id: string;
  title: string;
  startedAt: string;
  durationInSeconds: number;
  livePerformance: ShowTotalAndLivePerformance;
  totalPerformance: ShowTotalAndLivePerformance;
  type: ShowType;
  isPublished: boolean;
  amaResponsesCount: number;
}

export interface PastShowAuxData {
  ordersTotal: number;
  ordersLive: number;
  voucherCode?: string;
  streamedBy: StreamedBy;
  conversionOverview: conversionOverview;
}
export interface conversionOverview {
  androidConversionLive: number;
  androidConversionTotal: number;
  appConversionLive: number;
  appConversionTotal: number;
  webConversionLive: number;
  webConversionTotal: number;
  iosConversionLive: number;
  iosConversionTotal: number;
  conversionLive: number;
  conversionTotal: number;
}
export interface PastShowsWithCreatorsResponse extends PageableResponse {
  shows: PastShowWithCreator[];
}

export interface PastShowWithCreator extends PastShow {
  audience: Audience[];
  creator: {
    id: string;
    name: string;
    role: CreatorRole;
  };
}

export interface UpcomingShow {
  id: string;
  title: string;
  scheduledAt: string;
  numberOfProducts: number;
  previewImageUrl: string;
  type: ShowType;
  isPublished: boolean;
  isLive: boolean;
  products: ProductPreview[];
  voucherCode?: string;
}

export interface CreatorShows {
  upcoming: UpcomingShow[];
  past: PastShow[];
}

export enum ShowType {
  IVS = 'IVS',
  BAMBUSER = 'BAMBUSER',
}

export interface ShowUpdatePayload {
  showId: string;
  request: ScheduleShowData;
}

export interface ChangeShowVisibility {
  showId: string;
  isPublished: boolean;
  updateUpcomingShowsOverview?: boolean;
}

export interface AddProductToShow {
  showId: string;
  baseProductsNo: string[];
}

export enum ImageType {
  CURTAIN_BEFORE = 'CURTAIN_BEFORE',
  CURTAIN_AFTER = 'CURTAIN_AFTER',
  PREVIEW = 'PREVIEW',
}

export interface Creator {
  id?: string;
  name: string;
}

export interface RecordingResponse {
  startedAt: string;
  endedAt: string;
  rewatchUrl: string;
}

export interface ShowProductResponse {
  baseProductNo: string;
  variantId?: string;
}

export interface ImageKeys {
  preview?: string;
  curtainBefore?: string;
  curtainAfter?: string;
}

export type ShowImageExtension = 'png';

export interface ScheduleShowData extends ShowRequest {
  preview?: {
    imageUrl?: string;
    imageType: ImageType;
    fileExtension: ShowImageExtension;
    changed: boolean;
  };
}

export interface Curtains {
  afterImageUrl: string;
  beforeImageUrl: string;
  previewImageUrl: string;
}

export interface ShortLinkResponse {
  shortLink: string;
}

export interface ShowImages {
  curtainBeforeImageUrl?: string;
  curtainAfterImageUrl?: string;
  previewImageUrl?: string;
}

export interface ShowCoverSelectionMetadata {
  displayTitles: boolean;
  icon?: SvgIcon;
  title?: string;
  subTitle?: string;
}

export interface ShowImagesData {
  preview: ShowImageMetadata;
  curtainAfter: ShowImageMetadata;
  curtainBefore: ShowImageMetadata;
}

export interface ShowImageMetadata {
  imageType: CreatorImageType;
  fileExtension: ShowImageExtension;
  imageUrl?: string;
  changed: boolean;
}

export interface CreatorDetails {
  id: string;
  name: string;
  role: CreatorRole;
}

export interface ShowDetails {
  id: string;
  title: string;
  previewImageUrl: string;
  creatorDetails: CreatorDetails;
  scheduledAt?: string;
  startedAt?: string;
  endedAt?: string;
  durationInSeconds?: number;
  streamingKey?: string;
  streamingUrl?: string;
  products: ShowProductResponse[];
  isPublished: boolean;
  videoDownload?: VideoDownloadResponse;
  audience: Audience[];
  vouchers?: Voucher[];
  streamedBy: StreamedBy;
}

export interface TagsResponse {
  tags: string[];
}

type VideoDownloadResponse = {
  id: string;
  url: string;
};

export enum StreamedBy {
  HSE = 'HSE',
  CREATOR = 'CREATOR',
}

export interface ProductPreview {
  baseProductNo: string;
  variantId?: string;
  thumbnail?: string;
  soldOut?: boolean;
  voucher?: VoucherResponse;
  price?: ProductPrice;
}

export type ShowVideoDownload = VideoDownloadResponse;
