import { Button, EButtonType, ETag, Text } from '@hse24/shared-components';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { format, subMinutes } from 'date-fns';
import dayjs from 'dayjs';
import { ErrorMessage, Form, Formik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import t from '../../../../src/constants/translation';
import Icons from '../../../assets';
import FormikTextField from '../../../components/FormikTextField/FormikTextField';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import SubmitBtn from '../../../components/SubmitBtn/SubmitBtn';
import TextField from '../../../components/TextField/TextField';
import { AvailableFlag, Flag } from '../../../config/FeatureFlags';
import { LocalStorageKeys } from '../../../constants/localStorageKeys';
import { CreatorVoucher } from '../../../creators/model/creator';
import { fetchLoggedInCreator, selectLoggedInCreator } from '../../../creators/state/creatorsSlice';
import { selectProductSelection } from '../../../products/state/productsSlice';
import routePaths from '../../../routes/routePaths';
import { useShowImages } from '../../../shared/useShowImages';
import { sharedStorageService } from '../../../storage/sharedStorageService';
import { toBerlinTimeZone } from '../../../utils/dates';
import { ImageType, ScheduleShowData, ShowCoverSelectionMetadata } from '../../model/shows';
import { mapTags } from '../../utils/mapTags';
import { mapCurtainsToShowImages } from '../../utils/showImages';
import useFormChanged from '../../utils/useFormChanged';
import ShowCoverSelection from '../ShowCoverSelection/ShowCoverSelection';
import ShowProductsSelection from '../ShowProductsSelection/ShowProductsSelection';
import ApplyVouchers from './ApplyVouchers/ApplyVouchers';
import { ShowFormProps } from './ScheduleShowForm';
import styles from './ScheduleShowForm.module.scss';
import showSchema from './ShowSchema/ShowSchema';
import DateSelector from './ShowStartTimePicker/DateSelector/DateSelector';
import ShowStartTimePicker from './ShowStartTimePicker/ShowStartTimePicker';

export interface ShowData {
  title?: string | null;
  preview?: string;
  baseProductsNo: string[];
  tags?: string[];
  scheduledStartAt?: string;
  voucherId?: string;
}

const showCoverSelectionMetadata: ShowCoverSelectionMetadata = {
  displayTitles: false,
  icon: Icons.videoCamera,
  title: 'Vorschaubild',
  subTitle:
    'Du kannst ein Bild hochladen, das den Followern zeigt, was in ' +
    'deinem Stream passiert. Ein gutes Vorschaubild fällt auf und erzeugt Interesse bei den Zuschauern.',
};

const ClassicScheduleShowForm: FC<ShowFormProps> = ({
  isCreate,
  submitCallback,
  isOwnShow,
  showToUpdate,
}: ShowFormProps) => {
  const nowSub5min = useMemo(() => subMinutes(new Date(), 5), []);
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInCreator = useSelector(selectLoggedInCreator);
  const { selectedProducts } = useSelector(selectProductSelection);
  const [showImages] = useShowImages(mapCurtainsToShowImages(showToUpdate?.curtains));
  const [vouchers, setVouchers] = useState<CreatorVoucher[]>([]);
  const [title, setTitle] = useState<string>(showToUpdate?.title ?? '');
  const [preview, setPreview] = useState<string>(
    mapCurtainsToShowImages(showToUpdate?.curtains)?.previewImageUrl ?? ''
  );
  const [scheduledAt, setScheduledAt] = useState<Date | undefined>(
    showToUpdate?.scheduledAt ? dayjs(showToUpdate.scheduledAt).toDate() : undefined
  );
  const [voucherId, setVoucherId] = useState<string>('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const getScheduleAt = () => scheduledAt?.toISOString() ?? '';

  const hasFormChanged = useFormChanged<ShowData>(
    {
      title,
      preview,
      baseProductsNo: selectedProducts.map(p => p.baseProductNo),
      scheduledStartAt: getScheduleAt(),
      voucherId,
    },
    showToUpdate
      ? {
          title: showToUpdate.title,
          preview: mapCurtainsToShowImages(showToUpdate?.curtains)?.previewImageUrl,
          baseProductsNo: showToUpdate.products?.map(p => p.baseProductNo) || [],
          scheduledStartAt: showToUpdate.scheduledAt,
          voucherId: '',
        }
      : null
  );

  const initialValues: ScheduleShowData & { productSearch: string } = {
    header: showToUpdate?.header ?? '',
    title: showToUpdate?.title ?? '',
    tags: showToUpdate?.tags ? mapTags(showToUpdate.tags) : [],
    scheduledStartAt: showToUpdate?.scheduledAt ?? '',
    baseProductsNo: [], // Will be taken from the selector in the child component
    preview: showImages.previewImageUrl
      ? {
          imageType: ImageType.PREVIEW,
          fileExtension: 'png',
          imageUrl: showImages.previewImageUrl,
          changed: false,
        }
      : undefined,
    productSearch: '',
  };

  const [shouldDisplayHeaderField, setShouldDisplayHeaderField] = useState(false);
  const displayButton = isOwnShow || isCreate;

  useEffect(() => {
    dispatch(fetchLoggedInCreator());
    const displayHeaderFieldItem = sharedStorageService.getItem(
      LocalStorageKeys.displayHeaderField
    );
    setShouldDisplayHeaderField(displayHeaderFieldItem === 'true');
  }, []);

  useEffect(() => {
    setVouchers(loggedInCreator?.vouchers ?? []);
  }, [loggedInCreator]);

  const mapFormValuesToScheduleShowData = (
    values: ScheduleShowData & { productSearch: string }
  ): ScheduleShowData => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { productSearch, ...rest } = values;
    rest.scheduledStartAt = scheduledAt ? toBerlinTimeZone(scheduledAt).toISOString() : '';
    rest.baseProductsNo = selectedProducts.map(
      selectedProduct => selectedProduct.variantId ?? selectedProduct.baseProductNo
    );
    return rest;
  };

  const handleCancel = () => history.push(routePaths.creator.dashboard);

  const renderErrorMsg = (message: string) => {
    return (
      <MenuItem className={styles.products_error}>
        <ListItemIcon>
          <WarningAmberOutlined className={styles.error_message} />
        </ListItemIcon>
        <ListItemText>
          <Typography className={styles.error_message}>{message}</Typography>
        </ListItemText>
      </MenuItem>
    );
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={showSchema}
        onSubmit={values => {
          setIsFormSubmitted(true);
          submitCallback(mapFormValuesToScheduleShowData(values));
        }}
        initialTouched={{
          scheduledStartAt: false,
          title: false,
          baseProductsNo: false,
        }}
        initialErrors={{ baseProductsNo: '' }}
      >
        {({ setFieldTouched, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <div className={styles.wrapper} data-testid="classic-show-form">
                <Grid className={styles.container} container spacing={7}>
                  <Grid item xs={12} md={7}>
                    <Text.Body tag={ETag.DIV} className={styles.label}>
                      {t.creators.show['Scheduled stream start']}
                    </Text.Body>
                    <Grid item xs={12} mb={5}>
                      <ShowStartTimePicker
                        scheduledStartAt={getScheduleAt()}
                        onClick={() => setIsDateSelectorOpen(true)}
                        hasError={
                          errors.scheduledStartAt != undefined && touched.scheduledStartAt == true
                        }
                        isButton
                      />
                      <div className={styles.date_Selector}>
                        <FormikTextField
                          data-testid="scheduledStartAt"
                          fieldName="scheduledStartAt"
                          value={format(scheduledAt ?? nowSub5min, 'dd.MM.yyyy HH:mm')}
                          label="Startzeit"
                        />
                      </div>
                      <Box mt={2}>
                        <ErrorMessage name="scheduledStartAt">
                          {() => renderErrorMsg(t.validation['Please set a time'])}
                        </ErrorMessage>
                      </Box>
                    </Grid>
                    {isDateSelectorOpen && (
                      <DateSelector
                        title={t.creators.show.DateSelectorTitle}
                        isDialogOpen={isDateSelectorOpen}
                        closeDialog={() => {
                          setIsDateSelectorOpen(false);
                        }}
                        plannedAt={scheduledAt ?? nowSub5min}
                        updatePlannedAt={(date: Date) => {
                          setScheduledAt(date);
                          setFieldTouched('scheduledStartAt', true);
                          setFieldValue('scheduledStartAt', format(date, 'dd.MM.yyyy HH:mm'));
                        }}
                      />
                    )}
                    <Grid item xs={12}>
                      <Text.Body tag={ETag.DIV} className={styles.label}>
                        {t.creators.show['Stream title']}
                      </Text.Body>
                      {shouldDisplayHeaderField && (
                        <TextField fieldName="header" value={showToUpdate?.header} label="Header" />
                      )}
                      <TextField
                        fieldName="title"
                        value={showToUpdate?.title}
                        onChange={value => setTitle(value ?? '')}
                        label="Maximal 60 Zeichen"
                      />
                      {errors.title !== undefined &&
                        isSubmitClicked &&
                        renderErrorMsg(t.validation['Please enter a title'])}
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Text.Body tag={ETag.DIV} className={styles.label}>
                        {t.creators.show['Products']}
                      </Text.Body>
                      <ShowProductsSelection
                        hasError={
                          errors.baseProductsNo !== undefined &&
                          isSubmitClicked &&
                          (selectedProducts == undefined || selectedProducts.length == 0)
                        }
                        isButton
                      />
                      {errors.baseProductsNo !== undefined &&
                        isSubmitClicked &&
                        (selectedProducts == undefined || selectedProducts.length == 0) && (
                          <ErrorMessage name="baseProductsNo">
                            {() =>
                              renderErrorMsg(t.validation['Please include one or more products'])
                            }
                          </ErrorMessage>
                        )}
                    </Grid>
                    <Flag flag={AvailableFlag.enableApplyVoucher}>
                      {isCreate && vouchers.length > 0 ? (
                        <Grid item xs={12} className={styles.checkbox}>
                          <ApplyVouchers
                            vouchers={vouchers}
                            onChange={value => setVoucherId(value ?? '')}
                          />
                        </Grid>
                      ) : null}
                    </Flag>
                    <Grid container spacing={2} item xs={12} className={styles.desktop_actions}>
                      <Button onClick={handleCancel} type={EButtonType.BLANK}>
                        <Typography fontWeight={700}> {t.common.Cancel}</Typography>
                      </Button>
                      {displayButton && (
                        <SubmitBtn
                          disabled={false}
                          onClick={() => {
                            setIsSubmitClicked(true);
                          }}
                        >
                          {isCreate ? (
                            <Typography fontWeight={700}>Neuen Stream erstellen</Typography>
                          ) : (
                            <Typography fontWeight={700}> Stream ändern</Typography>
                          )}
                        </SubmitBtn>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Grid item xs={12}>
                      <ShowCoverSelection
                        hasError={
                          (errors.preview !== undefined || preview == '') &&
                          showImages.previewImageUrl == undefined &&
                          isSubmitClicked
                        }
                        fieldName="preview"
                        imageType={ImageType.PREVIEW}
                        defaultImage={showImages.previewImageUrl}
                        metadata={showCoverSelectionMetadata}
                        onChange={imageUrl => setPreview(imageUrl ?? '')}
                      />

                      {(errors.preview !== undefined || preview == '') &&
                        isSubmitClicked &&
                        showImages.previewImageUrl == undefined &&
                        renderErrorMsg(t.validation['Please insert a preview image'])}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={styles.actions}>
                  <Grid item>
                    <Button type={EButtonType.BLANK} onClick={handleCancel}>
                      <Typography fontWeight={700}> {t.common.Cancel}</Typography>
                    </Button>
                  </Grid>
                  {displayButton && (
                    <Grid item>
                      <SubmitBtn
                        size="large"
                        disabled={false}
                        onClick={() => {
                          setIsSubmitClicked(true);
                        }}
                      >
                        {isCreate ? (
                          <Typography fontWeight={700}>Neuen Stream erstellen</Typography>
                        ) : (
                          <Typography fontWeight={700}> Stream ändern</Typography>
                        )}
                      </SubmitBtn>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Form>
          );
        }}
      </Formik>
      <RouteLeavingGuard blockNavigation={hasFormChanged} navigationPermitted={isFormSubmitted} />
    </>
  );
};

export default ClassicScheduleShowForm;
